import React from 'react';
import { navigate } from 'gatsby';

export default function TrendingFirstPage() {
  React.useEffect(() => {
    navigate('/trending');
  });

  return null;
}
